<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>{{ $t('settings.lang_settings') }}</v-card-title>
          <v-card-text>
            <v-checkbox :disabled="loading" v-model="sendEmail" :label="$t('generic.lang_sendViaeMail')"></v-checkbox>
            <v-btn text small color="primary" @click="$router.push({name: 'settings.basicSettings.mainSettings'})">
              <v-icon>arrow_right</v-icon>
              {{ $t('settings.lang_emailSettings') }}
            </v-btn>

            <v-divider></v-divider>

            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                          :label="$t('generic.lang_emailReceipient')"
                          @focus="showTouchKeyboard"
                          autocomplete="off"
                          dense
                          :rules="emailRules"
                          outlined
                          required
                          v-model="sendereMail"
            ></v-text-field>

            <v-checkbox v-if="$store.getters['permissions/checkHybridEnabled']('Hybrid')" :disabled="loading" v-model="sendManagerReportAuto"
                        :label="$t('accounting.lang_send_manager_report_auto')"></v-checkbox>

            <v-checkbox :disabled="loading" v-model="closeCashBook"
                        :label="$t('accounting.lang_closeCashbookAfterDailyReport')"></v-checkbox>
            <v-checkbox :disabled="loading" v-model="resetReports"
                        :label="$t('accounting.lang_resetUserShiftReportAfterPrinting')"></v-checkbox>

            <v-checkbox :disabled="loading" v-model="open_cashDrawer"
                        :label="$t('accounting.lang_openCashDrawerForDailyReport')"></v-checkbox>

            <v-checkbox :disabled="loading" v-model="disable_print_interim_report"
                        :label="$t('accounting.lang_disableInterimReportPrint')"></v-checkbox>

            <v-checkbox :disabled="loading" v-model="disable_print_itemgroupAnalysis_report"
                        :label="$t('accounting.lang_disable_print_itemgroupAnalysis_report')"></v-checkbox>

            <v-checkbox :disabled="loading" v-model="disable_print_itemTypeAnalysis_report"
                        :label="$t('accounting.lang_disable_print_itemtypeAnalysis_report')"></v-checkbox>

            <v-checkbox :disabled="loading" v-model="printTipOnDailyReport"
                        :label="$t('accounting.lang_printTipsRecievedDailyReport')"></v-checkbox>
            <v-checkbox :disabled="loading" v-model="displayOpenTableWarning" :true-value="1" :false-value="0"
                        :label="$t('generic.lang_showDailyReportWarning')"></v-checkbox>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loading" :loading="loading" @click="saveSettings">
              {{ $t('generic.lang_save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";

export default {
  name: "DailyReportSettings",
  mixins: [mixin],
  data() {
    return {
      sendEmail: false,
      sendManagerReportAuto: false,
      closeCashBook: false,
      resetReports: false,
      open_cashDrawer: false,
      loading: false,
      sendereMail: "",
      disable_print_interim_report: false,
      disable_print_itemgroupAnalysis_report: false,
      disable_print_itemTypeAnalysis_report: false,
      printTipOnDailyReport: false,
      displayOpenTableWarning: 0,
      emailRules: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('generic.lang_noValidEmailAddress')
      ]
    }
  },

  mounted() {
    this.loadSettings();
  },

  methods: {
    loadSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.SETTINGS.GET).then((res) => {
        if (res.data.success) {
          this.sendEmail = res.data.settings.sendEmail === 1;
          this.closeCashBook = res.data.settings.closeCashbook === 1;
          this.resetReports = res.data.settings.resetReports === 1;
          this.sendereMail = res.data.settings.sendereMail;
          this.sendManagerReportAuto = res.data.settings.sendManagerReportAuto;
          this.open_cashDrawer = res.data.settings.open_cashDrawer;
          this.disable_print_interim_report = res.data.settings.disable_print_interim_report;
          this.disable_print_itemgroupAnalysis_report = res.data.settings.disable_print_itemgroupAnalysis_report;
          this.disable_print_itemTypeAnalysis_report = res.data.settings.disable_print_itemTypeAnalysis_report;
          this.printTipOnDailyReport = res.data.settings.printTipOnDailyReport;
          this.displayOpenTableWarning = res.data.settings.displayOpenTableWarning;
        }

        this.loading = false;
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });

        this.loading = false;
      })
    },
    saveSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.SETTINGS.UPDATE, {
        sendEmail: this.sendEmail ? 1 : 0,
        sendereMail: this.sendereMail,
        sendManagerReportAuto: this.sendManagerReportAuto,
        resetReports: this.resetReports,
        closeCashBook: this.closeCashBook ? 1 : 0,
        open_cashDrawer: this.open_cashDrawer ? 1 : 0,
        disable_print_interim_report: this.disable_print_interim_report ? 1 : 0,
        disable_print_itemgroupAnalysis_report: this.disable_print_itemgroupAnalysis_report ? 1 : 0,
        disable_print_itemTypeAnalysis_report: this.disable_print_itemTypeAnalysis_report ? 1 : 0,
        printTipOnDailyReport: this.printTipOnDailyReport ? 1 : 0,
        displayOpenTableWarning: this.displayOpenTableWarning
      }).then(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_actionSuccessful'),
          color: "success"
        });

        this.loading = false;
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });

        this.loading = false;
      })
    }
  }
}
</script>
