<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-row style="margin-top: 20px;">
          <v-col cols="12" sm="6">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="startDateT"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :label="$t('accounting.lang_dailyReportStart')"
                        :rules="[v => !!v]" dense outlined
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-card-text>
                          <v-date-picker
                              v-model="date"
                              no-title
                              scrollable
                          >
                            <v-spacer></v-spacer>
                          </v-date-picker>
                          <v-time-picker
                              v-model="startDateTime"
                              no-title
                              scrollable
                              format="24hr"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                              {{ $t('generic.lang_cancel') }}
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                :disabled="startDateTime === '' || this.date == ''"
                                @click="$refs.menu.save(date)"
                            >
                              {{ $t('generic.lang_ok') }}
                            </v-btn>
                          </v-time-picker>
                    </v-card-text>
                  </v-card>
                </v-menu>
              <v-spacer></v-spacer>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="endDateT"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :label="$t('accounting.lang_dailyReportEnd')"
                    :rules="[v => !!v]" dense outlined
                ></v-text-field>
              </template>
              <v-card>
                <v-card-text>
                  <v-date-picker
                      v-model="date2"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                  </v-date-picker>
                  <v-time-picker
                      v-model="endDateTime"
                      no-title
                      scrollable
                      format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu2 = false"
                    >
                      {{ $t('generic.lang_cancel') }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        :disabled="endDateTime === '' || this.date2 == ''"
                        @click="$refs.menu2.save(date2)"
                    >
                      {{ $t('generic.lang_ok') }}
                    </v-btn>
                  </v-time-picker>
                </v-card-text>
              </v-card>
            </v-menu>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row style="margin-top: 50px;" align="center" justify="center">
      <v-col class="text-center" cols="12" sm="6">
        <v-card :disabled="loadingManagerPrinting || startDateTime === '' || endDateTime === '' || this.date == '' || this.date2 == ''" :loading="loadingManagerPrinting" @click="printManagerReport" elevation="5">
          <div style="padding-top: 40px; padding-bottom: 40px;">
            <div class="text-center icons-container pa-3 blue lighten-5 mx-auto">
              <font-awesome-icon :icon="['fal' , 'user-tie']" class="success--text" prefix="fal" size="3x"/>
            </div>
            <v-card-title class="text-center">
              <span class="mx-auto">{{ $t('accounting.lang_printManagerReport') }}</span>
            </v-card-title>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core';
import {faUserTie} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {mapState} from "vuex";
import FileSaver from 'file-saver';

library.add(
    faUserTie
);

export default {
  name: "ManagerReportComponent",

  components: {
    'font-awesome-icon': FontAwesomeIcon
  },

  data() {
    return {
      loadingManagerPrinting: false,
      startDateTime: "",
      endDateTime: "",
      date: '',
      date2: '',
      menu: false,
      menu2: false,
    }
  },

  computed: {
    ...mapState([
        'api'
    ]),
    startDateT: function () {
      // `this` points to the vm instance
      return this.date+' '+this.startDateTime
    },
    endDateT: function () {
      // `this` points to the vm instance
      return this.date2+' '+this.endDateTime
    }
  },

  methods: {
    printManagerReport() {
      this.loadingManagerPrinting = true;
      this.axios.post(ENDPOINTS.ACCOUNTING.MANAGERREPORT.PRINTA4, {
            startDate: this.date+'T'+this.startDateTime,
            endDate: this.date2+'T'+this.endDateTime
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        //DOWNLOAD INVOICE AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "Managerreport.pdf");

        this.loadingManagerPrinting = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadingManagerPrinting = false;
      })
    },

  }
}
</script>

<style scoped>
.icons-container {
  width: 70px !important;
  height: 70px !important;
  border-radius: 100px !important;
}
</style>