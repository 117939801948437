<template>
  <v-container class="pa-0 transparent" fluid>
    <v-card class="pa-0 ma-0 transparent" elevation="0">
      <v-card-title class="pa-2 ma-0 pt-5 pb-5">
        <div class="transparent">
          <v-btn v-if="$store.getters['permissions/checkPermission']('dailyReport')"
                 :class="this.tabs != 0? 'transparent' : ''" :color="this.tabs == 0? 'primary' : ''"
                 :elevation="this.tabs == 0? '12' : '0'" @click="tabs = 0">
            {{ $t('accounting.lang_Printdaily') }}
          </v-btn>
          <v-btn v-if="$store.getters['permissions/checkPermission']('dailyReport')"
                 :class="this.tabs != 1? 'transparent' : ''" :color="this.tabs == 1? 'primary' : ''"
                 :elevation="this.tabs == 1? '12' : '0'" @click="tabs = 1">
            {{ $t('accounting.lang_cumulateDailyReports') }}
          </v-btn>

          <v-btn v-if="$store.getters['permissions/checkPermission']('dailyReportArchive')"
                 :class="this.tabs != 2? 'transparent' : ''" :color="this.tabs == 2? 'primary' : ''"
                 :elevation="this.tabs == 2? '12' : '0'" @click="tabs = 2">
            {{ $t('accounting.lang_DailyArchive') }}
          </v-btn>

          <v-btn :class="this.tabs != 3? 'transparent' : ''" :color="this.tabs == 3? 'primary' : ''"
                 :elevation="this.tabs == 3? '12' : '0'" @click="tabs = 3">
            {{ $t('accounting.lang_printManagerReport') }}
          </v-btn>

          <v-btn v-if="$store.getters['permissions/checkPermission']('dailyReportSettings')"
                 :class="this.tabs != 4? 'transparent' : ''" :color="this.tabs == 4? 'primary' : ''"
                 :elevation="this.tabs == 4? '12' : '0'" @click="tabs = 4">
            {{ $t('settings.lang_settings') }}
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="ma-0 pa-0 transparent elevation-0">
        <v-tabs-items v-model="tabs" class="transparent">
          <v-tab-item class="" v-if="$store.getters['permissions/checkPermission']('dailyReport')">
            <print-daily-component/>
          </v-tab-item>

          <v-tab-item class="" v-if="$store.getters['permissions/checkPermission']('dailyReport')">
            <bulk-print-daily-report-component/>
          </v-tab-item>

          <v-tab-item v-if="$store.getters['permissions/checkPermission']('dailyReportArchive')">
            <daily-archive-component/>
          </v-tab-item>

          <v-tab-item>
            <ManagerReportComponent></ManagerReportComponent>
          </v-tab-item>

          <v-tab-item v-if="$store.getters['permissions/checkPermission']('dailyReportSettings')">
            <DailyReportSettings></DailyReportSettings>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PrintDailyComponent from "./PrintDailyComponent";
import DailyArchiveComponent from "./DailyArchiveComponent";
import DailyReportSettings from "@/components/accounting/dailyreports/DailyReportSettings";
import ManagerReportComponent from "@/components/accounting/dailyreports/ManagerReportComponent";
import BulkPrintDailyReportComponent from "./BulkPrintDailyReportComponent";

export default {
  name: "DailyReportComponent",
  components: {
    BulkPrintDailyReportComponent,
    ManagerReportComponent, DailyReportSettings, PrintDailyComponent, DailyArchiveComponent},
  data: () => {
    return {
      tabs: 0
    }
  },
  mounted() {

  }
}


</script>

<style scoped>
.transparent {
  background-color: transparent !important;
}

.v-btn__content, .v-btn {
  text-transform: none !important;
}
</style>